import React,  {useState} from 'react';
import { useForm } from 'react-hook-form';
import { MenuToolbar } from '@/routes';

import { useMutation, usePut } from '@/components/Utils';
import { Card, Swal, checkError } from '@/components/UI';

import { informations } from '../UserField';
import { Link, useNavigate } from 'react-router-dom';

// Icon
import { AiOutlineFileText, AiOutlineWarning } from 'react-icons/ai';
import { FaPlus, FaKey } from 'react-icons/fa';
import { RiShieldKeyholeFill } from 'react-icons/ri';

// Select for societe and agences
import { SelectSearch, useUpload } from '@/components/UI';
import { errorUser } from '@/components/Utils';
import RoleMultiple from '../UserRoles/RoleMultiple';
import './UserForm.styles.scss';
import UserPassword from '../UserPassword/UserPassword.partial';

const ROLE_LISTS = [
    { name: "ROLE_ADMIN", permissionId: 1, allowAgences: false, key: 'admin' },
    { name: "ROLE_DIRECTEUR_SOCIETE", permissionId: 2, allowAgences: 'multiple', key: 'directeurSociete' },
    { name: "ROLE_DIRECTEUR_AGENCE", permissionId: 3, allowAgences: 'multiple', key: 'directeurAgence' },
    { name: "ROLE_NEGOCIATEUR", permissionId: 3, allowAgences: 'single', key: 'negociateur' },
]

const UserForm = ({ action, userDetail = {} }) => {
    const navigate = useNavigate();
    const [userError, setUserError] = useState({});
    const { getValues, setValue, clearErrors, register, handleSubmit, watch, formState: { isDirty, errors } } = useForm({
        defaultValues: {
            status: true,
            permissions: [],
            privileges: {},
            ...userDetail,
        },
        mode: "onSubmit",
        reValidateMode: "onChange"
    });

    const [saveUtilisateur, { fusion: saveFusion, error: errorCreate }] = useMutation('/users/create');
    const [updateUtilisateur, { fusion: updateFusion, error: errorDetails }] = usePut(`/users/${userDetail.id}`);
    const [updatePrivilegeUser] = useMutation(`/users/update`);

    const fusion = action === "update" ? updateFusion : saveFusion;

    watch('status');
    watch('permissions');
    watch('privileges');
    
    const permissions = getValues('permissions');
    const privileges = getValues('privileges');
    const status = getValues('status');

    React.useEffect(() => {
        // Dans le cas où on décoche un rôle on doit vider les privilèges
        ROLE_LISTS.map(role => {
            if (permissions && !permissions.includes(role.name)) {
                setValue(`privileges.${role.key}`, null)

                clearErrors(`privileges.${role.key}`);
            }

            return role;
        })

        return () => {}
        // eslint-disable-next-line
    }, [permissions])

    React.useEffect(()=>{
        if(errorDetails?.response?.data?.detail || errorCreate?.response?.data?.detail){
            const error = errorDetails?.response?.data?.detail || errorCreate?.response?.data?.detail;
            setUserError(errorUser(error));
        }else{
            setUserError({})
        }
    },[errorDetails, errorCreate])

    const params = {
        register,
        checkError,
        errors,
        setValue,
        clearErrors,
        privileges,
        permissions
    }

    const { showUploader, uploading } = useUpload({
        folder: `/images/user_avatars`,
        config:{
            width: 175,
            height: 175
        },
        defaultValue: userDetail?.avatar ?? null,
        onUploaded: ({ filePath }) => setValue(`avatar`, filePath, { shouldDirty: true })
    })

    const onSubmit = (data) => {

        if (action === "create"){
            saveUtilisateur({...data, status: data.status?1:0}).then((response) => {
                if (response.status === 201) {
                    navigate(`/users/liste`)
                }
            })
        }else if (action === "update"){
            updateUtilisateur({...data, status: data.status?1:0}).then((response) => {
                if (response.status === 200) {
                    Swal.fire({
                        title: 'Utilisateur mis à jour avec succès',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
            });

            const privileges = Object.assign({}, params.privileges);
            
            updatePrivilegeUser({...data, status: data.status?1:0, privileges: privileges});
        }
    }

    return (
        <>
            <form
                autoComplete={`off`}
                className="row user-form"
                onSubmit={handleSubmit(onSubmit)}>
                <MenuToolbar
                    title={
                        action === "update" ? "Modification utilisateur": "Ajout utilisateur"
                    }
                    subtitle={userDetail?.id ? userDetail?.nom + " " + userDetail?.prenom + " #" + userDetail?.id : null}
                    action={() => 
                    <button
                        {...fusion({ disabled: uploading === true || isDirty === false })}
                        className="btn btn-success btn-sm font-weight-bold mr-2">
                        <FaPlus /> Sauvegarder
                    </button>}>
                    <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                        <li className="breadcrumb-item text-muted">
                            <Link to="/users/liste" className="text-muted text-hover-primary">
                                Liste des utilisateurs
                            </Link>
                        </li>
                        <li className="breadcrumb-item">
                            <span className="bullet bg-gray-200 w-5px h-2px"></span>
                        </li>
                    </ul>
                </MenuToolbar>
                
                {/* Information de l'utilisateur */}
                <div className="col-md-6 user-info">
                    <Card
                        titleIcon={<AiOutlineFileText />}
                        titleColor={'#ff793f'}
                        title={`Information de l'utilisateur`}>

                        {/* // Upload de l'avatar de l'utilisateur */}
                        <div className="mb-2">
                            { showUploader() }
                        </div>
                        

                        <div className="row mb-5">
                            <div className="col-md-6 form-group">
                                <div className="form-label">{ `Nom` }</div>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        placeholder="Nom"
                                        className="form-control"
                                        {...register('nom', { required: true })}/>
                                </div>
                                { checkError('nom', errors) }
                            </div>

                            <div className="col-md-6 form-group">
                                <div className="form-label">{ `Prénom` }</div>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        placeholder="Prénom"
                                        className="form-control"
                                        {...register('prenom', { required: true })}/>
                                </div>
                                { checkError('prenom', errors) }
                            </div>
                        </div>

                        { informations && informations.map((information, key) => {
                            return (<div className="form-group mb-5" key={key}>
                                <div className="form-label">{ information.label }</div>
                                <div className="input-group">
                                    <input
                                        type={information.type ?? "text"}
                                        placeholder={ information.label }
                                        className="form-control"
                                        {...register(information.name, information.params)}/>
                                </div>
                                { checkError(information.name, errors) }
                                {userError.hasOwnProperty(information.name)? <div className="field-error">{userError[information.name]}</div>:null}
                            </div>)
                        }) }

                        {/* Champ password */}
                        { action === "create" ? (
                            <div className="form-group mb-5">
                                <div className="form-label">Password</div>
                                <div className="input-group">
                                    <input
                                        type={"password"}
                                        placeholder={"Mot de passe"}
                                        className="form-control"
                                        {...register(`password`, { required: true })}/>
                                </div>
                                { checkError(`password`, errors) }
                                
                            </div>
                        ) : null }
                        

                        <label className="form-check form-switch form-check-custom">
                            <div>Cet utilisateur peut se connecter au CRM ? : &nbsp;</div>
                            <input
                                className="form-check-input"
                                name="status"
                                type="checkbox"
                                value={true}
                                {...register('status')}/>

                            <span className="form-check-label fw-bold text-muted">{ parseInt(status) === 1 ? 'Oui' : 'Non'}</span>
                        </label>
                        
                    </Card>
                </div>
                
                {/* Rôle de l'utilisateur */}
                <div className="col-md-6 user-role">
                    <Card
                        titleIcon={<FaKey />}
                        titleColor={'#ff793f'}
                        title={`Rôle de l'utilisateur`}>
                        <div className="mb-4">
                            <div>Rôle(s) * Veuillez cocher au moins un rôle</div>
                            { checkError('permissions', errors, 
                            (<div className="alert alert-danger px-4 flex items-center" role="alert">
                                <AiOutlineWarning className="fs-2 me-2" /> Vous devez choisir au moins un rôle pour l'utilisateur
                            </div>)) }
                        </div>
                        
                        {/* Rôle administrateur */}
                        <div className="mb-4">
                            <label className="form-check">
                                <input
                                    className="form-check-input"
                                    name="role_admin"
                                    type="checkbox"
                                    value="ROLE_ADMIN"
                                    {...register('permissions', { required: true })}/>

                                <span className="form-check-label fw-bold">Administrateur</span>
                            </label>
                        </div>

                        {/* Rôle directeur de société */}
                        <div className="mb-4" style={{borderBottom: '1px dashed #ccc'}}>
                            <label className="form-check">
                                <input
                                    className="form-check-input"
                                    name="role_directeur_societe"
                                    type="checkbox"
                                    value="ROLE_DIRECTEUR_SOCIETE"
                                    {...register('permissions', { required: true })}/>

                                <span className="form-check-label fw-bold">Directeur de société</span>
                            </label>

                            <div className="mt-3 ms-10">
                                <div className="text-muted fs-6 fw-bold">Veuillez ajouter la/les société(s) dirigées par l'utilisateur : </div>
                                <RoleMultiple
                                    {...params}
                                    type={2}
                                    url={`societes`}
                                    role={{
                                        name: `ROLE_DIRECTEUR_SOCIETE`,
                                        key: 'directeurSociete'
                                    }}/>
                            </div>
                        </div>


                        {/* Rôle directeur d'agence */}
                        <div className="mb-6" style={{borderBottom: '1px dashed #ccc'}}>
                            <label className="form-check">
                                <input
                                    className="form-check-input"
                                    name="role_directeur_agence"
                                    type="checkbox"
                                    value="ROLE_DIRECTEUR_AGENCE"
                                    {...register('permissions', { required: true })}/>

                                <span className="form-check-label fw-bold">Directeur d'agence</span>
                            </label>

                            <div className="mt-3 ms-10">
                                <div className="text-muted fs-6 fw-bold">Veuillez ajouter les agences dirigées par l'utilisateur : </div>
                                <RoleMultiple
                                    {...params}
                                    url={`agences`}
                                    role={{
                                        name: `ROLE_DIRECTEUR_AGENCE`,
                                        key: 'directeurAgence'
                                    }}/>
                            </div>

                            
                        </div>
                        
                        {/* Rôle négociateur */}
                        <div className="mb-4">
                            <label className="form-check">
                                <input
                                    className="form-check-input"
                                    name="role_negociateur"
                                    type="checkbox"
                                    value="ROLE_NEGOCIATEUR"
                                    {...register('permissions', { required: true })}/>

                                <span className="form-check-label fw-bold">Négociateur</span>
                            </label>
                            
                            <div className="mt-3 ms-10">
                                <SelectSearch
                                    url={`agences`}
                                    defaultValue={privileges.negociateur}
                                    disabled={permissions && !permissions.includes("ROLE_NEGOCIATEUR")}
                                    onSelect={({ value }) => {
                                        setValue(`privileges.negociateur`, {
                                            ...privileges.negociateur,
                                            permissionId: 4,
                                            agenceId: value.id,
                                            nom: value.nom,
                                            type: 1
                                        });

                                        clearErrors(`privileges.negociateur`);
                                    }}/>

                                { permissions && permissions.includes("ROLE_NEGOCIATEUR") ? (
                                    <input type="hidden" {...register(`privileges.negociateur.agenceId`, { required: true })} />
                                ) : null }

                                { checkError('privileges.negociateur.agenceId', errors, `Vous devez choisir une agence`) }
                            </div>
                        </div>                    
                    </Card>
                </div>
            </form>
            
            { userDetail && userDetail?.id ? (
                <>
                    <div className="mt-8">
                        <Card
                            titleIcon={<RiShieldKeyholeFill />}
                            titleColor={'#ff793f'}
                            title={`Sécurité`}
                            description={`Changer le mot de passe de l'utilisateur ici`}>
                            <UserPassword
                                {...userDetail}/>
                        </Card>
                    </div>
                </>
            ) : null }
        </>
    )
}

export default UserForm
