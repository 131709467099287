// import React from 'react'
import './Dashboard.style.scss';
import SalesTurnover from './partials/SalesTurnover/SalesTurnover';
import SalesDetailed from './partials/SalesDetailed/SalesDetailed';
import SalesAgence from './partials/SalesAgence/SalesAgence';
import SalesNegociateurs from './partials/SalesNegociateurs/SalesNegociateurs';
// import SalesSociety from './Administrateur/SalesSociety/SalesSociety';

const Dashboard = () => {
    return (
        <div className="dashboard-page">
            
            <SalesTurnover />
            <SalesDetailed />
            <SalesAgence />
            <SalesNegociateurs />
            {/* <SalesSociety /> */}
        </div>
    )
}

export default Dashboard
