import React from 'react';
// import { useNavigate } from 'react-router-dom';

import { FaPlus } from 'react-icons/fa';

import { useForm, Controller } from 'react-hook-form';
import { Dropzone, Swal, checkError, CurrencyInput } from '@/components/UI';
import { useMutation, usePatch, handleDiff} from '@/components/Utils';
import FraisSelector from '../../FraisSelector/FraisSelector';
import PaiementTypeSelect from '../../PaiementTypeSelect/PaiementTypeSelect';
import { Currency } from '../../../../../components/UI';

const FraisAdd = ({ bienFrais = null, bienId, onSuccess }) => {
    const [selectedFrais, selectFrais] = React.useState(null);

    const initMontantTtc = () => {
        const ht = parseFloat(bienFrais?.montantHt ?? 0);
        const tva = ht * (20/100);
        return ht + tva;
    }

    const [montantTtc, setMontantTtc] = React.useState(initMontantTtc());
    const [montantHt, setMontantHt] = React.useState(bienFrais?.montantHt);

    const { setValue, register, handleSubmit, reset, control, formState: { errors, dirtyFields } } = useForm({
        mode: "onSubmit",
        reValidateMode: "onChange"
    });

    const [saveFrais, { fusion: saveFusion }] = useMutation(`/bien_frais`);
    const [updateFrais, { fusion: updateFusion }] = usePatch(`/bien_frais/${bienFrais?.id}`, {
        'Content-Type': 'application/merge-patch+json'
    });

    React.useEffect(() => {
        if (bienFrais) {
            reset({
                ...bienFrais,
                facture: bienFrais?.facture ? `/api/file_uploads/${bienFrais?.facture.id}` : null,
                paiementType: bienFrais?.paiement ? bienFrais?.paiement.id : null,
                prestataire: bienFrais?.prestataire ? bienFrais?.prestataire.id : null,
                montantHt: bienFrais?.montantHt
            });
        }

        return () => {}
    }, [bienFrais, reset])

    const fusion = (props) => bienFrais ? updateFusion(props) : saveFusion(props);

    const handleChange = (e) => {
        setMontantHt(e);
        const ht = parseFloat(e);
        const tva = ht * (20/100);
        const ttc = ht + tva;
        setMontantTtc(ttc);
    }

    const onSubmit = ({ ...rest }) => {
        let updateValues = handleDiff(dirtyFields, rest);
        updateValues = {
            ...rest,
            frais: `/api/frais_types/${selectedFrais.id}`,
            bien: `/api/bien_immobiliers/${bienId}`,
            paiement: `/api/paiement_types/${rest.paiementType}`,
            montantHt: String(montantHt)
        }

        if (rest.prestataire)
            updateValues[`prestataire`] = `/api/frais_prestataires/${rest.prestataire}`;

        if (bienFrais) {
            return updateFrais(updateValues).then(() => {
                Swal.fire({
                    title: 'Le frais a été mis a jour avec succès',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500
                });
    
                if (typeof onSuccess === 'function') onSuccess();
            })
        }

        return saveFrais(updateValues).then(() => {
            Swal.fire({
                title: 'Le frais a été ajouté avec succès',
                icon: 'success',
                showConfirmButton: false,
                timer: 1500
            });

            if (typeof onSuccess === 'function') onSuccess();
        })
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>            
            <div className="row">
                <div className="col-md-12">
                    <div className="mb-4">
                        <FraisSelector
                            defaultValue={bienFrais?.frais}
                            onSelect={(frais) => {
                                selectFrais(frais);
                            }}/>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-6">
                            <div className="col-md-12 form-group mb-4">
                                <div className="form-label">{ `Montant € HT *` }</div>
                                <div className="input-group">
                                    <Controller
                                        name="montantHt"
                                        rules={{ required: true }}
                                        defaultValue={0}
                                        control={control}
                                        render={({ field }) => {
                                            return <CurrencyInput 
                                                {...field}
                                                className={`form-control`}
                                                onChange={handleChange}
                                                defaultValue={bienFrais?.montantHt ?? 0}
                                                placeholder={`Montant € HT *`}/>
                                        }}/>
                                </div>
                                { checkError('montantHt', errors) }
                            </div>

                            <div className="col-md-12 form-group mb-4">
                                <div className="form-label">{ `Montant € TTC` }</div>
                                <div className="input-group">
                                    <div className='ms-4'>
                                        <Currency value={montantTtc}/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 form-group mb-4">
                                <div className="form-label">{ `Date règlement *` }</div>
                                <div className="input-group">
                                    <input
                                        type="date"
                                        placeholder="Date règlement *"
                                        className="form-control"
                                        {...register('dateReglement', { required: true })}/>
                                </div>
                                { checkError('dateReglement', errors) }
                            </div>

                            <div className="col-md-12 form-group mb-4">
                                <div className="form-label">{ `Moyen de paiement *` }</div>
                                <div className="input-group">
                                    <PaiementTypeSelect
                                        {...register('paiementType', { required: true })}/>
                                </div>
                                { checkError('paiementType', errors) }
                            </div>

                            { selectedFrais && selectedFrais.forcePrestataire === 1 ? (
                                <>
                                    <div className="col-md-12 form-group mb-4">
                                        <div className="form-label">{ `Choisir le prestataire *` }</div>

                                        { selectedFrais?.prestataires.map((prestataire, key) =>
                                            <div key={key} className="input-group mb-2">
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        value={prestataire.id}
                                                        type="radio"
                                                        name={`prestataire`}
                                                        defaultChecked={prestataire.id === bienFrais?.prestataire?.id}
                                                        {...register('prestataire', { required: true })}
                                                        id={`prestataire_${key}`}/>

                                                    <label className="form-check-label" htmlFor={`prestataire_${key}`}>
                                                        { prestataire.nom }
                                                    </label>
                                                </div>
                                            </div>
                                        ) }

                                        { checkError('prestataire', errors) }
                                    </div>
                                </>
                            ) : null }
                        </div>
                        
                        
                        <div className="col-md-6">
                            <div className="col-md-12 mb-6">
                                <label className="form-label fw-bold" style={{fontSize: '16px'}}>
                                    Facture du frais
                                </label>
                                <Dropzone
                                    className="small-dropzone"
                                    labelItem={`la facture du frais`}
                                    defaultValue={bienFrais && bienFrais?.facture ? bienFrais.facture : null}
                                    onUploaded={({ id, filePath }) => {
                                        if (filePath) {
                                            setValue('facture', `/api/file_uploads/${id}`, { shouldDirty: true })
                                        } else {
                                            setValue('facture', null, { shouldDirty: true })
                                        }
                                    }}/>
                            </div>

                            <div className="mb-4">
                                <label className="form-label fw-bold" style={{fontSize: '16px'}}>Commentaire</label>
                                <div className="form-inline">
                                    <textarea type="text"
                                        {...register('commentaire')}
                                        className="form-control"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    
 
                    <div className="col-md-12 form-group mb-4">
                        <button
                            {...fusion()}
                            className={`btn ${bienFrais ? `btn-warning` : `btn-primary`} btn-sm font-weight-bold mr-2`}>
                            <FaPlus /> {bienFrais ? `Mettre à jour` : `Sauvegarder le frais`}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default FraisAdd
