import React from 'react';
import { MenuToolbar } from '@/routes';
import { useNavigate } from 'react-router-dom';
import { FaFileAlt, FaUserAlt, FaCircleNotch } from 'react-icons/fa';
import { MdScanner } from 'react-icons/md';
import { connect } from "react-redux";
import { checkError } from '@/components/UI/FormHandler/functions';
import { FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { CurrencyInput } from '@/components/UI';

import { informations, adresses, proprietaires, acquereurs } from './BienField';

// import { toAbsoluteUrl } from '@/_metronic/helpers/AssetHelpers';
import { useForm, Controller } from 'react-hook-form'
import { Card, Swal, useUpload, Dropzone } from '@/components/UI';
import { useMutation, usePatch, handleDiff, useGet } from '@/components/Utils';
import DirecteurMultiple from '../../DirecteurMultiple';

const BienInformation = ({ 
        action,
        bienDetail,
        refetch,
        user
    }) => {

    const { data: listAgences, loading } = useGet(`/user/${user.user_id}/agences/${user?.privilegeSelected ? user.privilegeSelected : -1}`, {
        headers: {
            'Accept': 'application/ld+json'
        }
    });

    const { watch, getValues, reset, setValue, register, handleSubmit, control, formState: { errors, dirtyFields } } = useForm({
        defaultValues: {
            ...bienDetail,
        },
        mode: "onSubmit",
        reValidateMode: "onChange"
    });

    const navigate = useNavigate();

    const [saveBien, { fusion: saveFusion }] = useMutation('/bien_immobiliers');
    const [updateBien, { fusion: updateFusion }] = usePatch(`/bien_immobiliers/${bienDetail ? bienDetail.id : null}`, {
        'Content-Type': 'application/merge-patch+json'
    });

    watch('agenceId');
    watch('directeurs');
    const agenceId = parseInt(getValues('agenceId'));   
    let directeurs = getValues('directeurs') === "" ? [] : getValues('directeurs');

    const fusion = (props) => action === "create" ? saveFusion(props) : updateFusion(props);

    const onSubmit = ({ ...rest }) => {
        if (action === "update") {
            let updatedFields = handleDiff(dirtyFields, rest);

            if (updatedFields.agenceId) {
                updatedFields["agence"] = `/api/agences/${updatedFields.agenceId}`;
            }

            updateBien(updatedFields).then(() => {
                refetch();
                Swal.fire({
                    title: 'Bien immobilier mis à jour avec succès',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500
                });
            })
        } else if (action === "create") {
            let updatedFields = rest;

            updatedFields = {
                ...updatedFields,
                agence: '/api/agences/' + updatedFields.agenceId,
                directeurs: directeurs
            }

            saveBien(updatedFields).then((response) => {
                if (response.status === 201) {
                    reset();
                    navigate(`/biens/liste`);
                };
            })
        }
    }

    const hasUpdates = Object.keys(dirtyFields).length > 0;

    const { showUploader, uploading } = useUpload({
        folder: `/images/bien_immobiliers/photos`,
        config: {
            width: 200,
            height: 200
        },
        useIcon: true,
        label: `Photo du bien`,
        labelAllowed: ``,
        defaultValue: bienDetail.photo ?? null,
        onUploaded: ({ filePath }) => setValue(`photo`, filePath, { shouldDirty: true })
    })

    const title = action === "create" ? `Nouveau bien immobilier` : `Modification bien immobilier`;
    const subtitle = action === "update" && bienDetail ? bienDetail.titreAnnonce + ` #` + bienDetail.id : ``;

    return (
        <form
            autoComplete={`off`}
            className="row"
            onSubmit={handleSubmit(onSubmit)}>

            <MenuToolbar
                title={title}
                subtitle={subtitle}
                action={() => 
                <button
                    {...fusion({ disabled: !hasUpdates || uploading })}
                    className="btn btn-success btn-sm font-weight-bold mr-2">
                    <FaPlus /> Sauvegarder
                </button>}>
                <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                    <li className="breadcrumb-item text-muted">
                        <Link to="/biens/liste" className="text-muted text-hover-primary">Liste des biens immobiliers</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <span className="bullet bg-gray-200 w-5px h-2px"></span>
                    </li>
                </ul>
            </MenuToolbar>

            <div className="col-md-6">
                <Card
                    title={`Information du bien immobilier`}
                    titleIcon={<FaFileAlt />}
                    titleColor={`#0fbcf9`}>
                    <div className="form-group flex mb-5">
                        <div className="col-md-6 me-4">
                            <div className="form-label">Agence concernée</div>
                            <div className="input-group">
                                { loading === false ?
                                <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    defaultValue={bienDetail?.agenceId ?? ``}
                                    {...register("agenceId", { required: true })}>
                                        <option value={``}>---Choisir---</option>
                                        {listAgences && listAgences.map((agence)=>
                                            <option key={agence.id} value={agence.id} >{agence.nom}</option>
                                        )}
                                </select> : <div className="form-select">
                                                Chargement en cours <FaCircleNotch className="spin-quickly"/>
                                            </div>}
                            </div>
                            { checkError('agenceId', errors) }
                        </div>

                        <div className="col-md-6 ms-4">
                            <div className="form-label">Directeur(s) concerné(s)</div>
                            <div className="input-group">
                                { agenceId ? (
                                    <DirecteurMultiple
                                        agenceId={agenceId}
                                        setValue={setValue}
                                        directeursValues={directeurs}/>
                                    
                                ) : null }
                            </div>
                            <input
                                type="hidden"
                                {...register('directeurs', { required: false })}/>
                            { checkError('directeurs', errors, 'Vous devez choisir au moins un directeur') }
                        </div>
                    </div>

                    <div className="col-md-12 mb-5">
                        {/* // Upload de la photo du bien */}
                        <div className="mb-2">
                            { showUploader() }
                        </div>
                    </div>

                    { informations && informations.map((information, key) => {
                        return (<div className="form-group mb-5" key={key}>
                            <div className="form-label">{ information.label }</div>
                            <div className="input-group">
                                <input
                                    type={information.type ?? "text"}
                                    placeholder={ information.label }
                                    className="form-control"
                                    {...register(information.name, information.params)}/>
                            </div>
                            { checkError(information.name, errors) }
                        </div>)
                    }) }

                    { adresses && adresses.map((adresse, key) => {
                        return (<div className="form-group mb-5" key={key}>
                            <div className="form-label">{ adresse.label }</div>
                            <div className="input-group">
                                <input
                                    type={adresse.type ?? "text"}
                                    placeholder={ adresse.label }
                                    className="form-control"
                                    {...register(adresse.name, adresse.params)}/>
                            </div>
                            { checkError(adresse.name, errors) }
                        </div>)
                    }) }

                    <div className="row mb-5">
                        <div className="col-md-12 form-group">
                            <div className="form-label">{ `Description` }</div>
                            <div className="input-group">
                                <textarea
                                    type="text"
                                    placeholder="Description"
                                    rows="5"
                                    className="form-control"
                                    {...register('description', { required: true })}/>
                            </div>
                            { checkError('description', errors) }
                        </div>
                    </div>

                    <div className="row mb-5">
                        <div className="col-md-6 form-group">
                            <div className="form-label">{ `Code Postal` }</div>
                            <div className="input-group">
                                <input
                                    type="text"
                                    placeholder="Code Postal"
                                    className="form-control"
                                    {...register('codePostal', { required: true })}/>
                            </div>
                            { checkError('codePostal', errors) }
                        </div>

                        <div className="col-md-6 form-group">
                            <div className="form-label">{ `Ville` }</div>
                            <div className="input-group">
                                <input
                                    type="text"
                                    placeholder="Ville"
                                    className="form-control"
                                    {...register('ville', { required: true })}/>
                            </div>
                            { checkError('ville', errors) }
                        </div>
                    </div>

                    <div className="col-md-12 form-group">
                        <label className="form-label fw-bold" style={{fontSize: '16px'}}>
                            Prix FAI de l'annonce *
                        </label>
                        <div>
                            <Controller
                                name="prixAnnonce"
                                rules={{ required: true }}
                                defaultValue={0}
                                control={control}
                                render={({ field }) => {
                                    return <CurrencyInput 
                                    {...field}
                                    defaultValue={bienDetail.prixAnnonce ?? 0}
                                    className={`form-control`}
                                    placeholder={`Prix FAI de l'Annonce`}/>
                                }}/>
                        </div>
                        { errors.prixAnnonce && <p className="text-red-500">{ checkError(errors, 'prixAnnonce')  }</p> }
                    </div>
                </Card>
            </div>
            
            <div className="col-md-6">
                <Card
                    title={`Propriétaire`}
                    titleIcon={<FaUserAlt />}
                    titleColor={`#0fbcf9`}>
                    <div className="mb-4">
                        <div className="row mb-5">
                            <div className="col-md-6 form-group">
                                <div className="form-label">{ `Nom` }</div>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        placeholder="Nom"
                                        className="form-control"
                                        {...register('proprietaire.nom')}/>
                                </div>
                                {/* { checkError('proprietaire.nom', errors) } */}
                            </div>

                            <div className="col-md-6 form-group">
                                <div className="form-label">{ `Prénom` }</div>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        placeholder="Prénom"
                                        className="form-control"
                                        {...register('proprietaire.prenom')}/>
                                </div>
                                {/* { checkError('proprietaire.prenom', errors) } */}
                            </div>
                        </div>

                        { proprietaires && proprietaires.map((proprietaire, key) => {
                            return (<div className="form-group mb-5" key={key}>
                                <div className="form-label">{ proprietaire.label }</div>
                                <div className="input-group">
                                    <input
                                        type={proprietaire.type ?? "text"}
                                        placeholder={ proprietaire.label }
                                        className="form-control"
                                        {...register(proprietaire.name, proprietaire.params)}/>
                                </div>
                                { checkError(proprietaire.name, errors) }
                            </div>)
                        }) }

                    </div>           
                </Card>

                <Card
                    title={`Acquéreur`}
                    titleIcon={<FaUserAlt />}
                    titleColor={`#0fbcf9`}
                    className="mt-8">
                    <div className="mb-4">
                        <div className="row mb-5">
                            <div className="col-md-6 form-group">
                                <div className="form-label">{ `Nom` }</div>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        placeholder="Nom"
                                        className="form-control"
                                        {...register('acquereur.nom')}/>
                                </div>
                                {/* { checkError('acquereur.nom', errors) } */}
                            </div>

                            <div className="col-md-6 form-group">
                                <div className="form-label">{ `Prénom` }</div>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        placeholder="Prénom"
                                        className="form-control"
                                        {...register('acquereur.prenom')}/>
                                </div>
                                {/* { checkError('acquereur.prenom', errors) } */}
                            </div>
                        </div>

                        { acquereurs && acquereurs.map((acquereur, key) => {
                            return (<div className="form-group mb-5" key={key}>
                                <div className="form-label">{ acquereur.label }</div>
                                <div className="input-group">
                                    <input
                                        type={acquereur.type ?? "text"}
                                        placeholder={ acquereur.label }
                                        className="form-control"
                                        {...register(acquereur.name, acquereur.params)}/>
                                </div>
                                { checkError(acquereur.name, errors) }
                            </div>)
                        }) }
                    </div>           
                </Card>
            </div>

            <div className="col-md-12 mt-4">
                <Card
                    title={`Mandat scanné`}
                    titleIcon={<MdScanner />}
                    titleColor={`#0fbcf9`}>
                    <Dropzone
                        action={action}
                        defaultValue={bienDetail && bienDetail?.mandat ? bienDetail.mandat : null}
                        onUploaded={({ id, filePath }) => {
                            if (filePath) {
                                setValue('mandatScan', filePath, { shouldDirty: true });
                                setValue('mandat', `/api/file_uploads/${id}`, { shouldDirty: true })
                            } else {
                                setValue('mandatScan', null, { shouldDirty: true });
                                setValue('mandat', null, { shouldDirty: true })
                            }
                            
                        }}/>
                </Card>
            </div>
        </form>
    )
}
const mapStateToProps = (state) => {

    const { user } = state.AuthReducer;
    return {
      user,
    }
}

export default connect(mapStateToProps)(BienInformation)
