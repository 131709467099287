import { MenuToolbar } from '@/routes';
import './Performance.styles.scss';
import PerformanceBien from './PerformanceBien/PerformanceBien';
import PerformanceNegociateur from './PerformanceNegociateur/PerformanceNegociateur';


const Performance = () => {
    return (
        <div className="agence-page">
            <MenuToolbar
                title={`Performances`}
                >
                <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                    <li className="breadcrumb-item">
                        <span className="bullet bg-gray-200 w-5px h-2px"></span>
                    </li>
                </ul>

                <div className="previous-search">
                    <div className="search-date">
                        <div className="search-text">du</div> 
                        <div><input disabled={true} type="date" className="form-control"/></div>
                    
                        <div className="search-text">au</div> 
                        <div><input disabled={true} type="date" className="form-control"/></div>
                    </div>
                    <div className="previous-button">
                        <button disabled={true} className="btn btn-success btn-sm font-weight-bold mr-2">Valider</button>
                    </div>
                </div>
            </MenuToolbar>

            <PerformanceBien />
            <PerformanceNegociateur />
        </div>
    )
}

export default Performance
