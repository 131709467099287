import { useGet } from '@/components/Utils';
import { MultipleInput } from '@/components/UI';

const FilterByAgence = ({ onChange }) => {
    let { data, loading } = useGet(`/agences`, {
        headers: {
            'Accept': 'application/json'
        }
    });

    const agences = data ?? [];

    let agencesFilter = [{
        label: `Toutes les agences`,
        value: ''
    }];


    if (loading) return null;

    agences.map(agence => {
        return agencesFilter = [...agencesFilter, {
            label: agence.nom,
            value: agence.id
        }]
    })
    
    return (
        <MultipleInput
            options={agencesFilter}
            label={`Agence`}
            className="ms-2"
            withIcon={false}
            onChange={(value) => onChange(value)}
        />
    )
}

export default FilterByAgence