import BienForm from './partials/BienForm/BienForm.partial';
import { useGet } from '@/components/Utils';
import { LoadingAnimation } from '@/components/UI';

const BienUpdate = ({ bienId, action, tab }) => {
    const { data, loading, refetch } = useGet(`/bien_immobiliers/${bienId}`);

    if (loading) return <LoadingAnimation />

    const params = {
        bienDetail: data ?? {},
        action,
        tab,
        refetch
    }

    return <BienForm loading={loading} {...params}/>
};

export default BienUpdate
