import React from 'react';
import CurrencyInput from 'react-currency-input-field';

const CustomizedInput = React.forwardRef(({
    readOnly,
    className,
    placeholder,
    decimalSeparator = ".",
    defaultValue,
    decimalScale= 2,
    allowDecimals = true,
    suffix = " €",
    onChange,
    disabled
  }, ref) => {

  const handleChange = (value) => {
    console.log(value);
    if (typeof onChange === 'function') onChange(value);
  }

  return <CurrencyInput
    readOnly={readOnly}
    ref={ref}
    className={className}
    placeholder={placeholder}
    defaultValue={defaultValue ?? 0}
    decimalScale={decimalScale}
    allowDecimals={allowDecimals}
    decimalSeparator={decimalSeparator}
    onValueChange={handleChange}
    suffix={suffix}
    disabled={disabled}
  />;
})

export default CustomizedInput
