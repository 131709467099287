import React from 'react';
import { MenuToolbar } from '@/routes';
import { FaMinus } from 'react-icons/fa';
import { GiReceiveMoney } from 'react-icons/gi';
import { checkError } from '@/components/UI/FormHandler/functions';
import { FaPlus, FaPercentage } from 'react-icons/fa';
import { Link } from 'react-router-dom';

// import { toAbsoluteUrl } from '@/_metronic/helpers/AssetHelpers';
import { useForm, Controller } from 'react-hook-form';
import { Card, CurrencyInput, Swal } from '@/components/UI';
import { useMutation, useGet } from '@/components/Utils';
import FraisDetail from '../../FraisDetail/FraisDetail.partial';
import RepartitionCommission from './partials/RepartitionCommission';
import { useEffect } from 'react';
import { connect } from 'react-redux';

const BordereauCommission = ({ bienDetail, refetchBienImmobilier, roleSelected }) => {
    // Récupération des détails des frais
    const fraisDetail = useGet(`/frais/detail/${bienDetail.id}`);
    const frais = fraisDetail?.data ?? [];

    const totalFrais = frais.reduce((acc, value) => acc += parseFloat(value.montantHt), 0);

    // Récupération des négociateurs associés au bien immobilier
    const commissionQuery = useGet(`/commission/detail/${bienDetail.id}`);
    const commissions = React.useMemo(() => commissionQuery?.data?.commissions ?? [], [commissionQuery?.data?.commissions]);

    const commercial = bienDetail?.commercial;
    const bordereau = bienDetail?.bordereau;

    // React hook form pour la validation du bien immobilier
    const { register, reset, watch, getValues, setValue, handleSubmit, control, formState: { errors, dirtyFields } } = useForm({
        defaultValues: {
            validate: bienDetail?.status ?? false,
            totalFrais,
            rajoutCommission: bordereau?.rajoutCommission ?? 0,
            rajoutCommentaire: bordereau?.rajoutCommentaire ?? ``,
            soustraireCommission: bordereau?.soustraireCommission ?? 0,
            soustraireCommentaire: bordereau?.soustraireCommentaire ?? ``,
        },
        mode: "onSubmit",
        reValidateMode: "onChange"
    });

    useEffect(() => {
      if (commissionQuery.loading === false && commissions) {
        const allValues = getValues();
        reset({
            ...allValues,
            commissions
        })
      }
      return () => {}
    }, [commissionQuery.loading, commissions, getValues, reset])
    
    // Méthode pour valider le formulaire react hook form
    const [saveBien, { fusion: saveFusion }] = useMutation('/bordereau/save/');

    const fusion = (props) => saveFusion(props);

    watch('rajoutCommission');
    watch('soustraireCommission');
    watch('totalFrais');
    watch('validate');

    const rajoutCommission = parseFloat(getValues("rajoutCommission"));
    const soustraireCommission = parseFloat(getValues("soustraireCommission"));
    const validate = getValues("validate");

    const launchSave = (rest) => {
        const updatedFields = {
            ...rest,
            bienId: bienDetail.id,
            totalFrais,
            validate
        };

        saveBien(updatedFields).then((response) => {
            if (response?.status === 201) {
                refetchBienImmobilier();
            };
        })
    }

    const onSubmit = ({ ...rest }) => {
        if (parseInt(validate) === 1) {
            return Swal.fire({
                title: `<span style="color: blue">Clôturer le bordereau de commission?</span>`,
                icon: 'question',
                html: `Voulez-vous réellement clôturer le bordereau de commission?`,
                showCloseButton: false,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: 'Oui',
                confirmButtonColor: 'blue',
                cancelButtonText: 'Annuler'
            }).then(result => {
                if (result.isConfirmed) {
                    launchSave(rest);
                }
            })
        } else {
            launchSave(rest);
        }
    }

    const getCommissionProvenance = () => {
        let montantCommission = parseFloat(commercial?.montantCommission);
        montantCommission = Number.isNaN(montantCommission) ? 0.0 : montantCommission;
        const tauxCommission = parseFloat(commercial?.provenance?.tauxCommission) / 100.0
        return (montantCommission * tauxCommission);
    }

    const getCommissionTotal = () => {
        const montantRajoutCommission = Number.isNaN(rajoutCommission) ? 0.0 : parseFloat(rajoutCommission);
        const montantSoustraireCommission = Number.isNaN(soustraireCommission) ? 0.0 : parseFloat(soustraireCommission);
        return getCommissionProvenance() + (montantRajoutCommission - montantSoustraireCommission);
    }

    const getCommissionTotalDue = () => {
        // const tauxCommission = parseFloat(commercial?.provenance?.tauxCommission) / 100.0;
        return getCommissionTotal();
    }

    // Détail du formulaire : hasupdate, title and subtitle
    const hasUpdates = Object.keys(dirtyFields).length > 0;

    const title = `Bordereau commission`;
    const subtitle = ``;

    return (
        <form
            autoComplete={`off`}
            className="row"
            onSubmit={handleSubmit(onSubmit)}>

            <MenuToolbar
                title={title}
                subtitle={subtitle}>
                {/* action={() => 
                <button
                    {...fusion({ disabled: !hasUpdates })}
                    className="btn btn-success btn-sm font-weight-bold mr-2">
                    <FaPlus /> Sauvegarder
                </button>} */}
                <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                    <li className="breadcrumb-item text-muted">
                        <Link to="/biens/liste" className="text-muted text-hover-primary">Liste des biens immobiliers</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <span className="bullet bg-gray-200 w-5px h-2px"></span>
                    </li>
                </ul>
            </MenuToolbar>

            <div className="col-md-6">
                <Card
                    title={`Bordereau de commission`}
                    titleIcon={<GiReceiveMoney />}
                    titleColor={`#0fbcf9`}>

                    <div className="row mb-5">
                        <div className="col-md-12 form-group">
                            <div className="form-label">{ `Montant de la commission sur vente` }</div>
                            <div className="input-group">
                                <CurrencyInput
                                    type="text"
                                    placeholder="Montant de la commission sur vente"
                                    defaultValue={commercial?.montantCommission ?? 0}
                                    disabled={true}
                                    className="form-control"/>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-5">
                        <div className="col-md-12 form-group">
                            <div className="form-label">{ `Provenance du lead ${commercial?.provenance?.nom} (taux à ${commercial?.provenance?.tauxCommission} %)` }</div>
                            <div className="input-group">
                                <CurrencyInput
                                    type="text"
                                    placeholder="Provenance du lead"
                                    className="form-control"
                                    disabled={true}
                                    defaultValue={getCommissionProvenance()}/>
                            </div>
                            { checkError('tauxCommission', errors) }
                        </div>
                    </div>


                    <FraisDetail
                        frais={frais}
                        totalFrais={totalFrais}
                        loading={fraisDetail?.loading}
                        onReady={(totalFrais) => setValue('totalFrais', totalFrais)}
                        bienId={bienDetail.id}/>
                    
                    <div className="row mb-5">
                        <div className="col-md-12 form-group">
                            <div className="form-label"><FaPlus/> { `Montant à ajouter à la comission` }</div>
                            <div className="input-group">
                                <Controller
                                    name="rajoutCommission"
                                    control={control}
                                    render={({ field }) => {
                                        return <CurrencyInput 
                                            {...field}
                                            className={`form-control`}
                                            disabled={bordereau?.status === 1}
                                            defaultValue={bordereau?.rajoutCommission ?? 0}
                                            placeholder={`Montant à ajouter à la comission`}/>
                                    }}/>
                            </div>
                            { checkError('rajoutCommission', errors) }
                        </div>

                        <div className="col-md-12 mt-2 form-group">
                            <textarea 
                                placeholder="Commentaire"
                                className="form-control"
                                disabled={bordereau?.status === 1}
                                defaultValue={bordereau?.rajoutCommentaire ?? ``}
                                {...register('rajoutCommentaire')}/>
                        </div>
                    </div>

                    <div className="row mb-5">
                        <div className="col-md-12 form-group">
                            <div className="form-label"><FaMinus /> { `Montant à soustraire à la comission` }</div>
                            <div className="input-group">
                                <Controller
                                    name="soustraireCommission"
                                    control={control}
                                    render={({ field }) => {
                                        return <CurrencyInput 
                                            {...field}
                                            className={`form-control`}
                                            disabled={bordereau?.status === 1}
                                            defaultValue={bordereau?.soustraireCommission ?? 0}
                                            placeholder={`Montant à soustraire à la comission`}/>
                                    }}/>
                            </div>
                            { checkError('soustraireCommission', errors) }
                        </div>

                        <div className="col-md-12 mt-2 form-group">
                            <textarea 
                                placeholder="Commentaire"
                                className="form-control"
                                disabled={bordereau?.status === 1}
                                defaultValue={bordereau?.soustraireCommentaire ?? ``}
                                {...register('soustraireCommentaire')}/>
                        </div>
                    </div>
                </Card>
            </div>
            
            <div className="col-md-6">
                <Card
                    title={`Repartitions des commissions`}
                    titleIcon={<FaPercentage />}
                    titleColor={`#f18f01`}>
                    
                    <RepartitionCommission
                        fusion={fusion}
                        hasUpdates={hasUpdates}
                        register={register}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        watch={watch}
                        getCommissionTotal={getCommissionTotal}
                        getCommissionTotalDue={getCommissionTotalDue}
                        montantCommission={commercial?.montantCommission}
                        tauxCommission={commercial?.provenance?.tauxCommission ?? 0}
                        rajoutCommission={rajoutCommission}
                        soustraireCommission={soustraireCommission}
                        totalFrais={totalFrais}
                        bienDetail={bienDetail}
                        commissionQuery={commissionQuery}
                        bordereau={bordereau}
                        roleSelected={roleSelected}
                    />
                </Card>
            </div>
        </form>
    )
}

const mapStateToProps = (state) => {
    const { user } = state.AuthReducer;
    const { roleSelected, privilegeSelected } = user;
    return {
        roleSelected,
        privilegeSelected
    }
  }
  
  export default connect(mapStateToProps)(BordereauCommission)
