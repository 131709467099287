import React from 'react';
import { information } from './SocieteField';
import { MenuToolbar } from '@/routes';
import { Link, useNavigate } from 'react-router-dom';
import { Card, SelectSearch, Swal } from '@/components/UI';
import { checkError } from '@/components/UI/FormHandler/functions';
import { useForm, useFieldArray } from 'react-hook-form';

// Icon imports
import { AiFillPlusSquare } from 'react-icons/ai';
import { FaPlus } from 'react-icons/fa';

import { useGet, usePatch, useMutation } from '@/components/Utils';
import AgenceItem from './AgenceItem/AgenceItem.partial';
import './SocieteForm.styles.scss';

const SocieteForm = ({ societeId = null, action }) => {
    
    const { data, loading } = useGet(`/societes/${societeId}`);
    const navigate = useNavigate();
    const isUpdate = () => action === "update" && data;

    const { getValues, register, handleSubmit, reset, control, formState: { errors } } = useForm();

    const [updateSociete, { fusion: updateFusion }] = usePatch(`/societes/${societeId}`);
    const [saveSociete, { fusion: saveFusion }] = useMutation(`/societes`);

    const fusion = action === "update" ? updateFusion : saveFusion;

    const onSubmit = (data) => {

        if (action === 'update')
            return updateSociete({...data, societeId: parseInt(societeId)}).then(() => navigate(`/societes/liste`));

        return saveSociete(data).then(() => navigate(`/societes/liste`))
    }

    const groups = [{
        title: 'Information',
        fields: information,
        position: `right`
    }]

    const { fields, append, remove } = useFieldArray({
        control,
        name: "agences",
    });

    const agences = getValues('agences');

    React.useEffect(() => {
        if (data) {
            reset({
                ...data
            });
        }
    }, [data, reset]);

    const rightGroup = groups && groups.filter(group => group.position === `right`);

    const [temporary, setTemporary] = React.useState(null);
    
    const handleSelect = (value) => {
        setTemporary(value);
    }

    const addElement = (clearSelect) => {
        if (!temporary) return null;

        // Check if agence already exist
        const exist = agences.find(agence => agence.nom === temporary.nom);

        if (exist === undefined) {
            append({
                agence: `/api/agences/${temporary.id}`,
                nom: temporary.nom
            });
            clearSelect();
            setTemporary(null);
        } else {

            Swal.fire({
                title: `<span style="color: red">L'agence existe déjà</span>`,
                icon: 'error',
                html: `L'agence est déjà existante dans la liste`,
                showCloseButton: false,
                showConfirmButton: false,
                timer: 1500
            });
            clearSelect();
            setTemporary(null);
        }
        
    }

    const deleteElement = (index) => {
        Swal.fire({
            title: `<span style="color: red">Supprimer l'agence?</span>`,
            icon: 'warning',
            html: `Voulez-vous réellement supprimer cette agence?`,
            showCloseButton: false,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Oui',
            confirmButtonColor: 'red',
            cancelButtonText: 'Annuler'
        }).then(result => {
            if (result.isConfirmed) {
                remove(index)
            }
        })
    }

    return (
        <form
            className={`societe-form ${isUpdate() ? `societe-update` : `societe-create`}`}
            onSubmit={handleSubmit(onSubmit)}>
            <MenuToolbar
                title={isUpdate() ? `Modification de la société > ${data.nom}` : `Ajout d'une nouvelle société`}
                action={() => 
                    <button
                        {...fusion()}
                        type="submit" 
                        className="btn btn-success btn-sm font-weight-bold mr-2">
                        <AiFillPlusSquare /> Sauvegarder
                    </button>}>
                <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                    <li className="breadcrumb-item text-muted">
                        <Link to="/societes/liste" className="text-muted text-hover-primary">Liste des sociétés</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <span className="bullet bg-gray-200 w-5px h-2px"></span>
                    </li>
                </ul>
            </MenuToolbar>
            
            { !loading ? (
                <div className="row">
                    
                    <div className="societe-info right-group">
                        { rightGroup && rightGroup.map(({ title, fields }, key) => {
                            return (
                            <Card
                                key={key}
                                title={title}
                                className="mb-4">
                                { fields && fields.map((field, index) => {
                                    return (
                                    <div className="mb-8" key={index}>
                                        <div className="col-12 align-items-center input-group has-validation">
                                            <label className="form-label col-12 text-start pe-8">{ field.label }</label>
                                            <div className="col-12 form-inline">
                                                <input
                                                    placeholder={ field.placeholder ?? field.label }
                                                    className="form-control form-control-white"
                                                    type={field.type ?? 'text'}
                                                    {...register(field.name, field.params)}/>
                                            </div>
                                            <div className="col-12"></div>
                                            <div className="col-12">
                                                { checkError(field, errors) }
                                            </div>
                                        </div>
                                    </div>)
                                }) }
                            </Card>)
                        })}

                        
                    </div>
                    <div className="societe-agences left-group">
                        <Card title={`Agences liées à la société`}>
                            <div className="agences-list mb-4">
                                <table className="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
                                    <thead>
                                        <tr className="border-0">
                                            <th className="p-0 min-w-150px"></th>
                                            <th className="p-0 min-w-50px"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { fields.map((field, index) => (
                                            <AgenceItem
                                                key={index}
                                                {...field}
                                                remove={() => deleteElement(index)}/>
                                        )) }
                                    </tbody>
                                </table>
                                
                            </div>

                            <div>Ajouter les agences liées à la société</div>
                            <div className="d-flex mt-4">
                                <SelectSearch
                                    url={`/agences/availables`}
                                    className="flex-auto me-2"
                                    // disabled={permissions && !permissions.includes(role.name)}
                                    onSelect={({ value }) => handleSelect(value)}
                                    append={({ clearSelect }) => 
                                        <button
                                            disabled={temporary === null}
                                            className="btn btn-success"
                                            type="button"
                                            onClick={() => addElement(clearSelect)}>
                                            <FaPlus /> Ajouter
                                        </button>
                                    }/>
                            </div>           
                        </Card>
                    </div>
                </div>
            ) : null}
        </form>
    )
}

export default SocieteForm
