import './RepartitionCommission.styles.scss';
import { Currency, CurrencyInput } from '@/components/UI';
import { useFieldArray } from 'react-hook-form';
import { FaCircleNotch, FaExclamationTriangle } from 'react-icons/fa';

const RepartitionCommission = ({
    fusion,
    hasUpdates,
    register,
    setValue,
    control,
    getCommissionTotal,
    getCommissionTotalDue,
    montantCommission,
    tauxCommission,
    rajoutCommission,
    soustraireCommission,
    totalFrais,
    bienDetail,
    commissionQuery,
    bordereau,
    roleSelected
  }) => {
  // const commissionTotal = getCommissionTotal();
  const commissionTotalDue = getCommissionTotalDue();

  const { fields, update } = useFieldArray({
    control,
    name: "commissions"
  });

  const { validatedAt, validatedBy } = bordereau ?? {};

  const negociateurs = fields.filter(field => field.type === "negociateur");
  const directeurs = fields.filter(field => field.type === "directeur");

  const checkPercentage = () => {
    const totalPercentage = fields
      .filter(field => field.type === "negociateur")
      .reduce((acc, value) => acc += parseFloat(value.taux), 0);

    return totalPercentage === 100;
  }

  const { loading } = commissionQuery;

  // Récupération du montant de part selon le pourcentage
  const getCommissionPart = (index) => {
    if (fields[index]?.taux)
      return (fields[index].taux * commissionTotalDue / 100)

    return 0;
  }

  // Récupération du montant de part selon le pourcentage
  const getCommissionDirecteur = (index) => {
    if (fields[index]?.taux)
      return (fields[index].taux * montantCommission / 100)

    return 0;
  }

  const getTotalCommissionDirecteur = () => {
    let totalCommissionDirecteur = 0;
    directeurs.map((directeur) =>
      totalCommissionDirecteur = totalCommissionDirecteur + getCommissionDirecteur(directeur.index)
    );

    return totalCommissionDirecteur;
  }

  // Récupération de la marge restante à moriss
  const getMargeBruteCommission = () => {
    return parseFloat(montantCommission) - commissionTotalDue - getTotalCommissionDirecteur() - parseFloat(totalFrais ?? 0) - soustraireCommission - rajoutCommission;
    // return parseFloat(montantCommission) - (getCommissionTotalDue() + parseFloat(totalFrais ?? 0));
  }

  if (loading === true) return (<div>Chargement en des répartitions <FaCircleNotch className="spin-quickly"/></div>)
  
  return (
    <div className="mt-5">
      {/* Commission sur vente */}
      <div className="commission-grandtotal">
        <div className="value-display">
          <div>Commission totale dûe :</div> &nbsp;
          <Currency value={commissionTotalDue}/>
        </div>
        <div className="value-formula">
        <Currency value={commissionTotalDue}/> = 
        (<Currency value={montantCommission}/> x { tauxCommission }% + <Currency value={rajoutCommission}/> - <Currency value={soustraireCommission}/>)
        </div>
      </div>

      <div className="commission-subtitle">N&Eacute;GOCIATEUR</div>
      {/* List des commissions */}
      <div className="commission-list">
        {negociateurs.map((negociateur) =>
          <div className="commission-item" key={negociateur.index}>

            <div className="commission-wrapper">
              <div className="commission-title">
                Commission pour&nbsp;
                <span style={{textDecoration: 'underline'}}>
                  {negociateur.nom} {negociateur.prenom}
                </span>
              </div>

              <div className="commission-amount">
                <div className="commission-percentage">
                  <input
                    type="number"
                    name="pourcentage"
                    min={0}
                    step="0.01"
                    className="form-control"
                    defaultValue={negociateur.taux}
                    disabled={bordereau?.status === 1}
                    onChange={(event) => update(negociateur.index, {
                      ...fields[negociateur.index],
                      taux: event.target.value
                    })}/>
                  <span>% = </span>
                  <input type="hidden" {...register(`commissions.${negociateur.index}`, { required: true })} />
                </div>

                <div className="commission-part">
                  <Currency value={getCommissionPart(negociateur.index)}/>
                </div>

                {roleSelected !== 4 && <div className="commission-ispaid">
                  <label className="form-check input-checkbox">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultChecked={negociateur.regler}
                      disabled={bordereau?.status === 1}
                      onChange={function(event) {
                        const checked = event.target.checked;
                        update(negociateur.index, {
                          ...fields[negociateur.index],
                          regler: checked ? 1 : -1
                        });
                      }}/>
                    <span className="form-check-label fw-bold">
                      Réglé par Moriss
                    </span>
                  </label>
                </div>}
              </div>
            </div>

            <div className="commission-wrapper">
              <div className="commission-title commission-avanced">dont acompte déjà versé</div>
              <div className="commission-amount">
                <div className="commission-percentage">
                  <CurrencyInput
                    name="pourcentage"
                    defaultValue={negociateur.acompte}
                    disabled={bordereau?.status === 1}
                    onChange={(value) => update(negociateur.index, {
                      ...fields[negociateur.index],
                      acompte: value
                    })}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      

      <div className="commission-subtitle">DIRECTEUR D'AGENCE</div>
      {/* Liste DIRECTEUR */}
      <div className="commission-list">
        {directeurs.map((directeur) =>
          <div className="commission-item" key={directeur.index}>

            <div className="commission-wrapper">
              <div className="commission-title">
                Commission pour&nbsp;
                <span style={{textDecoration: 'underline'}}>
                  {directeur.nom} {directeur.prenom}
                </span>
              </div>

              <div className="commission-amount">
                <div className="commission-percentage" lang="en-US">
                  <input
                    type="number"
                    name="pourcentage"
                    min={0}
                    step="0.25"
                    lang="en"
                    className="form-control"
                    disabled={bordereau?.status === 1}
                    defaultValue={directeur.taux}
                    onChange={(event) => update(directeur.index, {
                      ...fields[directeur.index],
                      taux: event.target.value
                    })}/>
                  <span>% = </span>
                </div>

                <div className="commission-part">
                  <Currency value={getCommissionDirecteur(directeur.index)}/>
                </div>
                    
                {roleSelected !== 4 && <div className="commission-ispaid">
                  <label className="form-check input-checkbox">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultChecked={directeur?.regler}
                      disabled={bordereau?.status === 1}
                      onChange={function(event) {
                        const checked = event.target.checked;
                        update(directeur.index, {
                          ...fields[directeur.index],
                          regler: checked ? 1 : -1
                        });
                      }}/>
                    <span className="form-check-label fw-bold">
                      Réglé par Moriss
                    </span>
                  </label>
                </div>}
              </div>
            </div>

            <div className="commission-wrapper">
              <div className="commission-title commission-avanced">dont acompte déjà versé</div>
              <div className="commission-amount">
                <div className="commission-percentage">
                  <CurrencyInput
                    name="pourcentage"
                    defaultValue={directeur.acompte}
                    disabled={bordereau?.status === 1}
                    onChange={(value) => update(directeur.index, {
                      ...fields[directeur.index],
                      acompte: value
                    })}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Marge restante à moriss */}
      <div className="pb-2 commission-marge">
        <div className="value-display">
          Marge restante à Moriss :&nbsp;
          <Currency value={getMargeBruteCommission()}/>
        </div>
        <div className="value-formula">

        <Currency value={getMargeBruteCommission()}/> = <Currency value={montantCommission}/> - <Currency value={commissionTotalDue}/>
         {directeurs.length > 0 ? ( directeurs.map((directeur) => (<> - <Currency key={directeur.index} value={getCommissionDirecteur(directeur.index)}/> </>)) ) : <> </> }
         - ( <Currency value={totalFrais}/> - <Currency value={soustraireCommission}/>) - <Currency value={rajoutCommission}/>
        {/* <Currency value={getMargeBruteCommission()}/> = <Currency value={montantCommission}/> - (<Currency value={commissionTotalDue}/> + <Currency value={totalFrais}/>) */}

        </div>
      </div>
      
      { bordereau?.status <= 0 || !bordereau ? (
        <>
          <div className="form-check mt-5 mb-5">
            <label className="form-check-label">
                <input
                    className="form-check-input"
                    type="checkbox"
                    disabled={roleSelected === 4 || bienDetail.etat !== 3?true:false}
                    onChange={(event) => {
                      const checked = event.target.checked;
                      setValue('validate', (checked ? 1 : 0), { shouldDirty: true });
                    }}/>
                    Je valide définitivement le bordereau et je fige l'ensemble des données de cette vente
            </label>
          </div>
        
          <button
            {...fusion({ disabled: !checkPercentage() || bienDetail.etat !== 3})}
            className="btn btn-primary"
            style={{width: '100%'}}>
            Valider
          </button>

          { !checkPercentage() && hasUpdates ? (
            <div className="alert alert-warning mt-4 flex items-center justify-center" role="alert">
              <FaExclamationTriangle /> &nbsp; 
              <div className="ms-4">La somme des parts des commissions pour les négociateurs est incorrecte</div>
            </div>) : 
          null }
        </>
      ) : null}
      

      { bordereau?.status >= 1 ? (
      <div className="alert alert-warning">
        Le bordereau de commission a été validé par : <br />
        <span style={{fontWeight: "bold", textDecoration: "underline"}}>{validatedBy?.nom} {validatedBy?.prenom}</span> le { validatedAt }
      </div>) : null }
    </div>
  )
}


export default RepartitionCommission;
