
import { Link } from 'react-router-dom';
import BienInformation from './BienInformation/BienInformation';
import BienFrais from './BienFrais/BienFrais.js';
import BienCommercial from './BienCommercial/BienCommercial.js';
import BordereauCommission from './BordereauCommission/BordereauCommission.js';
import './BienForm.styles.scss';

const BienForm = ({ action, tab, bienDetail = {}, refetch }) => {
    const handleActive = (activeTab = undefined) => {
        if (activeTab === tab) 
            return `active`;

        return ``;
    }

    const params = {
        refetch,
        action,
        bienDetail
    }

    const handleTabRender = () => {
        switch (tab) {
            case `bordereau`:
                return <BordereauCommission
                    refetchBienImmobilier={refetch}
                    {...params}/>

            case `frais`:
                return <BienFrais {...params}/>

            case `suivi-commercialisation`:
                return <BienCommercial {...params}/>

            default:
                return <BienInformation {...params}/>;
        }
    }

    return (
        <div className={`bien-form`}>
            {/* Liste des menu pour le tabs */}
            <ul className="container-fluid nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8">
                <li className="nav-item">
                    <Link
                        to={`/biens/update/${bienDetail.id}`}
                        className={`nav-link text-active-primary pb-4 ${handleActive()}`}>Le bien immobilier & contacts</Link>
                </li>
                { action === "update" ? (
                    <>
                        <li className="nav-item">
                            <Link
                                to={`/biens/update/${bienDetail.id}/suivi-commercialisation`} 
                                className={`nav-link text-active-primary pb-4 ${handleActive('suivi-commercialisation')}`}>Suivi de la commercialisation</Link>
                        </li>

                        <li className="nav-item">
                            <Link
                                to={`/biens/update/${bienDetail.id}/frais`}
                                className={`nav-link text-active-primary pb-4 ${handleActive('frais')}`}>Frais</Link>
                        </li>

                        { bienDetail?.commercial ? (
                            <li className="nav-item">
                                <Link
                                    to={`/biens/update/${bienDetail.id}/bordereau`}
                                    className={`nav-link text-active-primary pb-4 ${handleActive('bordereau')}`}>Bordereau de commission</Link>
                            </li>
                        ) : null }
                        
                    </>
                ) : null }
            </ul>
            
            { handleTabRender() }
        </div>
    )
}

export default BienForm
