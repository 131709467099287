import { MenuToolbar } from '@/routes';
import { Link } from 'react-router-dom';

import { useGet, useHydra, useFilter } from '@/components/Utils';
import { Card, DataTable, SearchInput, Badge, LoadingAnimation } from '@/components/UI';
import './ProvenanceListe.styles.scss';
import { FaRegEdit } from 'react-icons/fa';

const ProvenanceListe = () => {
    let [params, query] = useFilter();
    const { data, loading } = useGet(`/provenances${query}`, {
        headers: {
            'Accept': 'application/ld+json'
        }
    })

    const { rows, total, getPagination } = useHydra(data, params);

    const columns = [{
        name: 'nom',
        label: 'Provenance',
        component: ({ nom }) => <span className="text-dark fw-bolder text-hover-primary fs-6">{ nom }</span>
    }, {
        name: 'tauxCommission',
        label: 'Taux de commission',
        component: ({ tauxCommission }) => (<Badge color="success">{ tauxCommission }%</Badge>)
    }, {
        name: 'etat',
        label: 'Etat',
        component: ({ etat }) => etat === 1 ? (<Badge color="primary">Actif</Badge>) : (<Badge color="danger">Inactif</Badge>)
    }, {
        label: 'Action',
        component: ({ id }) => <Link to={`/provenances/update/${id}`}><FaRegEdit /></Link>
    }]

    return (
        <>
            <MenuToolbar
                title={`Provenance & Taux`}
                action={() => <Link to="/provenances/create" className="btn btn-primary btn-sm font-weight-bold mr-2">Créer nouvelle provenance</Link>}>
                <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                    <li className="breadcrumb-item text-muted">
                        <span className="text-muted text-hover-primary">{ total } trouvées</span>
                    </li>
                    <li className="breadcrumb-item">
                        <span className="bullet bg-gray-200 w-5px h-2px"></span>
                    </li>
                </ul>

                <SearchInput
                    label={`Nom`}
                    onChange={(value) => params.updates({
                        nom: value,
                        page: 1
                    })}/>
            </MenuToolbar>

            <div className="relative">
                { loading ? <LoadingAnimation /> : (
                    <>
                        <Card className="relative societe-list mb-4">
                            <DataTable
                                className="societe-datatable"
                                classique={true}
                                columns={columns}
                                rows={rows}/>
                        </Card>

                        { getPagination() }
                    </>
                ) }
            </div>
        </>
    )
}

export default ProvenanceListe

