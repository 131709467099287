import { MenuToolbar } from '@/routes';
import { Link } from 'react-router-dom';
import UserBox from '../UserBox/UserBox';
import { useGet, useFilter } from '@/components/Utils';
import { SearchInput, MultipleInput, LoadingAnimation } from '@/components/UI';
import './UserList.styles.scss';

const UserList = () => {
    let [params, query] = useFilter();
    
    const { data, loading } = useGet(`/users/list${query}`, {
        headers: {
            'Accept': 'application/json'
        }
    });

    const rows = data?.users ?? [];

    return (
        <div className="user-list">
            <MenuToolbar
                title={`Liste des utilisateurs`}
                action={() => <Link to="/users/create" className="btn btn-primary btn-sm font-weight-bold mr-2">Créer un utilisateur</Link>}>
                
                
                <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                    <li className="breadcrumb-item text-muted">
                        <span className="text-muted text-hover-primary">{ rows.length } trouvés</span>
                    </li>
                    <li className="breadcrumb-item">
                        <span className="bullet bg-gray-200 w-5px h-2px"></span>
                    </li>
                </ul>
                
                <div className="toolbar-search">
                    <SearchInput
                        label={`Nom`}
                        onChange={(value) => params.updates({
                            nom: value,
                            page: 1
                        })}/>
                    
                    <SearchInput
                        label={`Prénom`}
                        className="ms-2"
                        withIcon={false}
                        onChange={(value) => params.updates({
                            prenom: value,
                            page: 1
                        })}/>

                    <MultipleInput
                        options={[{
                            label: `Tous les rôles`,
                            value: ``
                        },{
                            label: `Que les administrateurs`,
                            value: `ROLE_ADMIN`
                        }, {
                            label: `Que les directeurs de société`,
                            value: `ROLE_DIRECTEUR_SOCIETE`
                        }, {
                            label: `Que les directeurs d'agences`,
                            value: `ROLE_DIRECTEUR_AGENCE`
                        }, {
                            label: `Que les négociateurs`,
                            value: `ROLE_NEGOCIATEUR`
                        }]}
                        label={`Rôle`}
                        className="ms-2"
                        withIcon={false}
                        onChange={(value) => params.updates({
                            roles: value,
                            page: 1
                        })}/>

                    <MultipleInput
                        options={[{
                            label: `Tout`,
                            value: ''
                        }, {
                            label: `Que les actifs`,
                            value: 1
                        }, {
                            label: `Que les inactifs`,
                            value: -1
                        }]}
                        label={`Status`}
                        className="ms-2"
                        withIcon={false}
                        onChange={(value) => params.updates({
                            status: value,
                            page: 1
                        })}/>
                </div>
            </MenuToolbar>


            <div className="relative">
                { loading ? (<LoadingAnimation className={`mb-4`}/>) : null }
                
                { rows && rows.map((user, key) => {
                    return <UserBox
                                className="mb-4"
                                key={key}
                                user={user} />
                })}
            </div>
        </div>
    )
}

export default UserList
