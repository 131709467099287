import React from 'react'

const MultipleInput  = ({ 
        label,
        options,
        onChange,
        timer = 800,
        withIcon = true,
        className,
        defaulValue
    }) => {
    const [trigger, setTrigger] = React.useState(null);


    const handleSearch = (event) => {
        const value = event.target.value;
        if (trigger) clearInterval(trigger);

        setTrigger(setTimeout(function() {
            if (typeof onChange === 'function') onChange(value)
        }, timer))
    }

    return (
        <div className={`d-flex align-items-center position-relative my-1 ${className}`}>
            { withIcon ? (
                <span className="svg-icon svg-icon-1 position-absolute ms-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
                        <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black"></path>
                    </svg>
                </span>
            ) : null }
            
            <select
                className={`form-control form-control-sm w-150px ${withIcon ? `ps-10` : `ps-4`}`}
                data-kt-customer-table-filter="search"
                onChange={handleSearch}
                placeholder={label}
                defaultValue={defaulValue}
                type="text">
                { options && options.map((option, index) => <option key={index} value={option.value}>{ option.label }</option>)}
            </select>
        </div>
    )
}

export default MultipleInput
