import React from 'react';
import './SalesNegociateurs.style.scss';
import ReactApexChart from 'react-apexcharts';
import { Card } from '@/components/UI';
import { useGet } from '@/components/Utils';
import { currencyFormat } from '@/components/UI';
// import { FaSpinner } from 'react-icons/fa';
import { connect } from 'react-redux';

const SalesNegociateurs = ({ roleSelected, privilegeSelected }) => {
  const [year, setYear] = React.useState(new Date().getFullYear())
  const { data, loading, refetch } = useGet('/dashboard/negociateurs?year='+year);

  const { data: yearData } = useGet('/dashboard/years');
  const years = yearData?.years;

  React.useEffect(() => {
    refetch();

    return () => {}
  }, [roleSelected, privilegeSelected, year])

  if (loading) return null;

  const negociateurs = data?.negociateurs ?? [];
  const categories = data?.categories ?? [];;
  const series = data?.series ?? [];

  const datas = {
    series: [{
      name: 'Chiffre d\'affaire',
      data: series
    }],
    options: {
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        title: {
          text: 'Négociateurs'
        },
        categories: categories,
      },
      yaxis: {
        title: {
          text: '€'
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " €" 
          }
        }
      }
    }
  }

  return (
      <Card
        title={`Année ${year} > Chiffre d'affaire par négociateurs`}
        className="mb-4">
        {/* Filtre de la table */}
        <div className="negociateurs-sales">
            <div className="negociateurs-block">
              <p className="negociateurs-description">Chiffre d'affaire par négociateurs</p>
              <div className="select-block">
                  <select
                      defaultValue={year}
                      className="form-select"
                      aria-label="Default select example"
                      onChange={(event) => setYear(event.target.value)}>
                      {years && years.map((y, index) =>
                          (<option key={index} value={y.year}>{y.year}</option>))}
                  </select>
                </div>
            </div>
 

        </div>

        {/* Chart et summary */}
        
        <div className="negociateurs-wrapper">
          <div className="negociateurs-chart">
            <div>
              <ReactApexChart options={datas.options} series={datas.series} type="bar" height={350} />
            </div>
          </div>

          <div className="negociateurs-table">
            <table>
              <thead>
                <tr className="first-column">
                  <th>Nom du négociateur</th>
                  <th className="currency">€ HT</th>
                </tr>
              </thead>
              <tbody>
                {!negociateurs || negociateurs.length <= 0 ? (<tr><td colSpan={2}>Aucun résultat n'est disponible</td></tr>) : null}
                {negociateurs && negociateurs.map((negociateur, index)=>
                  <tr key={index}>
                    <td>{negociateur.nom} {negociateur.prenom}</td>
                    <td className="currency">{currencyFormat(negociateur.chiffreAffaire)}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Card>
  )
}

const mapStateToProps = (state) => {
  const { user } = state.AuthReducer;
  const { roleSelected, privilegeSelected } = user;
  return {
      roleSelected,
      privilegeSelected
  }
}

export default connect(mapStateToProps)(SalesNegociateurs);
