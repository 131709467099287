import React, { useCallback, useRef } from 'react';
import { MenuToolbar } from '@/routes';
import { Link, useSearchParams } from 'react-router-dom';
import { useGet, useFilter, useHydra } from '@/components/Utils';
import { SearchInput, MultipleInput, LoadingAnimation, DataTable, Currency } from '@/components/UI';
import { connect } from 'react-redux';

// Icon
import { FaRegEdit } from 'react-icons/fa';
import { GiHouse } from 'react-icons/gi';

import { Card, Badge } from '@/components/UI';
import { publicAssets, toAbsoluteUrl } from '@/_metronic/helpers';

import './BienList.styles.scss';
import FilterByAgence from '../../../components/Filter/FilterByAgence/FilterByAgence.component';
import FilterByUser from '../../../components/Filter/FilterByUser/FilterByUser.component';

const BienList = ({ roleSelected, privilegeSelected }) => {
    let [params, query] = useFilter();
    const [searchParams, setSearchParams] = useSearchParams();
    let userId = searchParams.get("user");

    query = query === '' && userId ? '?user='+userId+'&pagination=false' : query;

    const { data, loading, refetch } = useGet(`/bien_immobiliers${query}`, {
        headers: {
            'Accept': 'application/ld+json'
        }
    });

    React.useEffect(() => {
        
        refetch();
    
        return () => {}
    }, [roleSelected, privilegeSelected, userId])

    const handleUpdate = useCallback((value) => {

        params.updates({
            user: value,
            pagination: value === '' ? 'true' : 'false'
        });

        userId = value;

    }, []) 
    

    const { rows, total, getPagination } = useHydra(data, params);

    const photoUrl = (photo) => {
        if (photo)
            return publicAssets(photo);
        else 
            return toAbsoluteUrl('/media/house.png');
    }

    const columns = [{
        name: 'photoPath',
        label: 'Photo',
        component: ({ photo, titreAnnonce }) => 
            <div className="bien-picture">
                <img src={photoUrl(photo)} alt={titreAnnonce}/>
            </div>
    }, {
        name: 'adresse',
        label: 'Adresse',
        component: ({ titreAnnonce, adressePrimaire, adresseSecondaire, codePostal, ville }) => (
        <div className="bien-info">
            <div className="fw-bold">{ titreAnnonce }</div>
            <div>{ adressePrimaire }</div>
            <div>{ adresseSecondaire }</div>
            <div>{ codePostal } { ville }</div>
        </div>)
    }, {
        name: 'prixAnnonce',
        label: 'Prix de vente FAI',
        component: ({ prixAnnonce }) => <Currency value={prixAnnonce ? prixAnnonce : 0.00} />,
        styles: {
            whiteSpace: 'nowrap'
        }
    }, {
        label: 'Prix Effectif',
        isEmpty: `0.00`,
        component: ({ commercial }) => <Currency value={(commercial && commercial?.prixEffectif) ? commercial.prixEffectif : 0.00} />,
        styles: {
            whiteSpace: 'nowrap'
        }
    }, {
        name: 'commission',
        label: 'Commission',
        isEmpty: `0.00`,
        component: ({ commercial }) => <Currency value={(commercial && commercial?.montantCommission) ? commercial.montantCommission : 0.00} />,
        styles: {
            whiteSpace: 'nowrap'
        }
    }, {
        label: 'Provenance du lead / Négociateur',
        isEmpty: `0.00`,
        component: ({ mandatType, commercial, negociateurs }) => {
            return (
            <>
                { commercial && commercial?.provenance ? (
                    <div>
                        <span className="badge-provenance fw-bold">{ commercial?.provenance ? commercial?.provenance?.nom : null }</span>
                        <div className="flex">
                            <div className="mandat-type fw-bold">{ parseInt(mandatType) === 1 ? `Mandat simple` : `Mandat exclusif` }</div>
                        </div>
                    </div>
                ) : null }

                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                    { negociateurs && negociateurs.map(negociateur => <div className="badge badge-light mb-2">{ negociateur.nom + ` ` + negociateur.prenom }</div> ) }
                </div>
            </>)
        }
    }, {
        name: 'status',
        label: 'Etat',
        component: ({ etat }) => {
            switch (parseInt(etat)) {
                case 2:
                    return <Badge color="primary">Promesse signée</Badge>;

                case 3:
                    return <Badge color="success">Acte définitif signé</Badge>;

                default: 
                    return `-`;
            }
        }
    }, {
        label: 'Action',
        component: ({ id }) => <Link to={`/biens/update/${id}`}><FaRegEdit /></Link>
    }]

    return (
        <div className="bien-list">
            <MenuToolbar
                title={`Biens immobiliers`}
                action={() => <Link to="/biens/create" className="btn btn-primary btn-sm font-weight-bold mr-2">Créer un bien immobilier</Link>}>
                <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                    <li className="breadcrumb-item text-muted">
                        <span className="text-muted text-hover-primary">{ total } trouvés</span>
                    </li>
                    <li className="breadcrumb-item">
                        <span className="bullet bg-gray-200 w-5px h-2px"></span>
                    </li>
                </ul>
                
                <SearchInput
                    label={`Code postal`}
                    onChange={(value) => params.updates({
                        codePostal: value,
                        page: 1
                    })}/>
                
                <SearchInput
                    label={`Ville`}
                    className="ms-2"
                    withIcon={false}
                    onChange={(value) => params.updates({
                        ville: value,
                        page: 1
                    })}/>
                
                <MultipleInput
                    options={[{
                        label: `Tous les états`,
                        value: ''
                    }, {
                        label: `Bien en vente sans promesse`,
                        value: 1
                    }, {
                        label: `Avec promesse signé`,
                        value: 2
                    }, {
                        label: `Avec signature définitive (tous)`,
                        value: 3
                    }, {
                        label: `Avec signature définitive (sans paiement du Notaire)`,
                        value: 4
                    }, {
                        label: `Avec signature définitive (avec paiement du Notaire)`,
                        value: 5
                    }]}
                    label={`Status`}
                    className="ms-2"
                    withIcon={false}
                    onChange={(value) => {
                        
                        let appendFilters = {};

                        switch (parseInt(value)) {
                            case 4:
                                appendFilters = {
                                    etat: 3,
                                    'commercial.paiementNotaire': -1
                                };
                                break;

                            case 5:
                                appendFilters = {
                                    etat: 3,
                                    'commercial.paiementNotaire': 1
                                };
                                break;

                            default:
                                appendFilters = {};
                                params.remove('commercial.paiementNotaire')
                                break;
                        }

                        params.updates({
                            etat: value,
                            page: 1,
                            ...appendFilters
                        })
                    }}/>

                <FilterByAgence
                    onChange={(value) => params.updates({
                        agence: value,
                        page: 1
                    })}/>

                <FilterByUser
                    onChange={handleUpdate}
                    dataUser={userId}
                    />

            </MenuToolbar>


            <div className="relative">
                { (
                    <>
                        { loading ? (<LoadingAnimation className={`mb-4`}/>) : null}

                        <Card
                            title={`Liste des biens immobiliers`}
                            titleIcon={<GiHouse />}
                            titleColor={`#00b894`}>
                            <DataTable
                                className="bien-datatable"
                                classique={true}
                                columns={columns}
                                rows={rows}/>
                        </Card>

                        <div className="mt-4">
                            { getPagination() }
                        </div>
                    </>
                ) }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const { user } = state.AuthReducer;
    const { roleSelected, privilegeSelected } = user;
    return {
        roleSelected,
        privilegeSelected
    }
}

export default connect(mapStateToProps)(BienList)
