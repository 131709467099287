import { useGet } from '@/components/Utils';
import { MultipleInput } from '@/components/UI';

const FilterByUser = ({ onChange, dataUser }) => {
    
    let { data, loading } = useGet(`/users`, {
        headers: {
            'Accept': 'application/json'
        }
    });

    const users = data ?? [];

    let usersFilter = [{
        label: `Toutes les utilisateurs`,
        value: ''
    }];


    if (loading) return null;

    users.map(user => {
        return usersFilter = [...usersFilter, {
            label: user.nom + ' ' + user.prenom,
            value: user.id
        }]
    })
    
    return (
        <MultipleInput
            options={usersFilter}
            label={`Utilisateur`}
            defaulValue={dataUser}
            className="ms-2"
            withIcon={false}
            onChange={(value) => onChange(value)}
        />
    )
}

export default FilterByUser