import React from 'react';
import './SalesDetailed.style.scss'
import { useQuery } from '@/components/Utils';
// import { currencyFormat } from '@/components/UI';

import { Card } from '@/components/UI';
import ReactApexChart from 'react-apexcharts';
import { Currency } from '@/components/UI';
import { useGet } from '../../../../components/Utils';
import { connect } from 'react-redux';

const SalesDetailed = ({roleSelected, privilegeSelected}) => {
    const [year, setYear] = React.useState(new Date().getFullYear())
    
    const { data, loading, refetch } = useQuery('/dashboard/detailed', {
        variables: {
            year
        }
    });

    const { data: yearData } = useGet('/dashboard/years');
    const years = yearData?.years;

    React.useEffect(() => {
        if (year) refetch();
    
        return () => {}
    }, [year, roleSelected, privilegeSelected])

    const series = data?.series ?? [];
    const labels = data?.labels ?? [];
    const stats = data?.stats ?? {};

    const datas = { 
        series: series,
        options: {
            chart: {
                width: '100%',
                type: 'donut',
            },
            labels,
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: '100%',
                    },
                }
            }]
        }
    }

    if (loading) return null;

    return (
    <Card
        title={`Année ${year} > Chiffre d'affaire HT détaillé`}
        className="mb-4 detailed-sales">
        <div className="sales-year">
            <div className="sales-subtitle">
                Chiffre d'affaire HT détaillé
            </div>
            <div className="select-block">
                <select
                    defaultValue={year}
                    className="form-select"
                    aria-label="Default select example"
                    onChange={(event) => setYear(event.target.value)}>
                    {years && years.map((y, index) =>
                        (<option key={index} value={y.year}>{y.year}</option>))}
                </select>
                
            </div>
            {/* <div className="sales-button">
                <button className="btn btn-success btn-sm font-weight-bold mr-2">Valider</button>
            </div> */}
        </div>
        <div className="flex">
            <div className="sales-table">
                <div className="table-wrapper">
                    <div className="table-block">
                        <table className="table-summary">
                            <thead>
                                <tr className="first-column">
                                    <th>Type</th>
                                    <th className="currency">€ HT</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Montant total de vente</td>
                                    <td className="currency"><Currency value={stats.totalVente} /></td>
                                </tr>
                                <tr>
                                    <td>Montant total de commission</td>
                                    <td className="currency"><Currency value={stats.totalCommission} /></td>
                                </tr>
                                <tr>
                                    <td>Bénéfice Moriss</td>
                                    <td className="currency"><Currency value={stats.margeCommission} /></td>
                                </tr>
                                <tr>
                                    <td>Frais payés</td>
                                    <td className="currency"><Currency value={stats.paidFrais} /></td>
                                </tr>
                                <tr>
                                    <td>Frais avancés</td>
                                    <td className="currency"><Currency value={stats.avancedFrais} /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    
                </div>
            </div>

            <div id="chart" className="chart-wrapper">
                <div style={{width: '100%'}}>
                    <ReactApexChart
                        options={datas.options}
                        series={datas.series}
                        type="donut"/>
                </div>
                
            </div>
        </div>
    </Card>
  )
};

const mapStateToProps = (state) => {
    const { user } = state.AuthReducer;
    const { roleSelected, privilegeSelected } = user;
    return {
        roleSelected,
        privilegeSelected
    }
  }
  
export default connect(mapStateToProps)(SalesDetailed);
