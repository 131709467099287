const information = [{
    name: "nom",
    label: "Provenance *",
    params: { required: true } 
}, {
    name: "tauxCommission",
    label: "Taux *",
    params: { required: true },
    appendText : "%"
}]

export {
    information
};