import { useGet, useFilter } from '@/components/Utils';

import { Card, DataTable, LoadingAnimation } from '@/components/UI';

import './../Performance.styles.scss';
import { connect } from 'react-redux';
import { useEffect, useMemo } from 'react';
import './PerformanceNegociateur.styles.scss';

const PerformanceNegociateur = ({roleSelected, privilegeSelected}) => {
    let [params, query] = useFilter();

    const { data, loading, refetch } = useGet(`/dashboard/negociateurs${query}`, {
        headers: {
            'Accept': 'application/ld+json'
        }
    });

    const { data: listProvenances } = useGet(`/provenances${query}`, {
        headers: {
            'Accept': 'application/json'
        }
    });
    
    const columnProvenance = useMemo(() =>{
        const arrayColumn = [];
        if (listProvenances) {
            listProvenances.map((item) => {
                const data = {
                    label: item.nom,
                    component: ({ provenance }) => {
                        let total = 0;
                        provenance.length > 0 && provenance.map((value)=>{      
                            item.nom === value.nomProvenance ? total += value.nombreBien : total+= 0; 
                        })
                        return  <span className={total > 0 ? 'badge badge-success' : ''}> {total} </span>
                    }
                }
                return arrayColumn.push(data);
            })
        }

        return arrayColumn
    }, [listProvenances])

    const rows = data?.negociateurs ?? [];

    useEffect(() => {
        refetch();
        return () => {}
    }, [roleSelected, privilegeSelected])

    const columns = [{
        name: 'type',
        label: '',
        component: ({ nom, prenom }) => (<span className="text-dark fw-bolder text-hover-primary fs-6">{ nom } {prenom}</span>)
    }, {
        label: 'Nombre de biens',
        component: ({ nombreVente }) => (
            <span className={nombreVente > 0 ? 'badge badge-primary' : 'badge badge-danger'}>{nombreVente}</span>
            )
    }, ...columnProvenance]

    return (
        <div className="relative performance-negociateur mt-5">
            { loading ? (<LoadingAnimation className={`mb-4`}/>) : (
                <>
                    <Card 
                        className="relative mb-4 agence-list"
                        titleColor={`#00000`}
                        title={'Nombre de biens par négociateur'}
                        >
                        <DataTable
                            className="performance-provenance"
                            classique={true}
                            columns={columns}
                            rows={rows}/>
                    </Card>

                </>
            ) }
        </div>
    )
}

const mapStateToProps = (state) => {
    const { user } = state.AuthReducer;
    const { roleSelected, privilegeSelected } = user;
    return {
        roleSelected,
        privilegeSelected
    }
}

export default connect(mapStateToProps)(PerformanceNegociateur)