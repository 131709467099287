import React from 'react';
import { useGet, useFilter } from '@/components/Utils';
import SelectItem from './partials/SelectItem.partial';
import './SelectSearch.styles.scss';
import { connect } from 'react-redux';
import { FaSpinner } from 'react-icons/fa';

const SelectSearch = ({
    onSelect,
    append,
    url = '',
    config = {
        field: 'nom',
        label: 'nom'
    },
    className,
    style,
    disabled,
    placeholder = `---Sélectionner---`,
    timer = 800,
    defaultValue,
    roleSelected,
    privilegeSelected
}) => {
    let [params, query] = useFilter();

    const selectRef = React.useRef(null);
    const searchInputRef = React.useRef(null);
    // const [trigger, setTrigger] = React.useState(null);
    const [show, toggleShow] = React.useState(false)
    const [value, setValue] = React.useState(defaultValue ?? null);
    const { data, loading, refetch } = useGet(`${url}${url.split('?') ? '' : query}`, {
        headers: {
            'Accept': 'application/json'
        }
    });

    const [rows, setRows] = React.useState(data ?? []);

    const handleSearch = (event) => {

        const value = event ? event.target.value : "";

        setRows(data.filter(function(item) {

            if(Array.isArray(config.field)){
                
                for (let field of config.field) {
                    if (item[field].toLowerCase().includes(value.toLowerCase()))
                    return true;
                }
                return false;
            
            }else{

                return item[config.field].toLowerCase().includes(value.toLowerCase())

            }
           
        }));

        // if (trigger) clearInterval(trigger);

        // setTrigger(setTimeout(function() {
        //     params.set(config.field, value);
        // }, timer))
    }

    const clearSelect = () => {
        setValue(null);
        if (searchInputRef.current) searchInputRef.current.value = "";
        params.set(config.field, null);
    }

    const multipleLabel = (v, c, k = 'label') => {
        if (Array.isArray(c[k])) {
            let fV = ``;
            c[k].map(item => {
                fV = fV + v[item] + ` `
                return fV
            })

            return fV;
        } else {
            return v[c[k]]
        }
    }

    React.useEffect(() => {
        if (disabled) clearSelect()
        return () => {}
        // eslint-disable-next-line
    }, [disabled])

    React.useEffect(() => {
        document.addEventListener('click', (event) => {
            if (selectRef.current) {
                if (!selectRef.current.contains(event.target)) toggleShow(false);
            }
        })

        return () => {
            document.removeEventListener('click', null);
        }
    })

    React.useEffect(() => {
        refetch();
    
        return () => {}
    }, [roleSelected, privilegeSelected])

    const handleChange = (value) => {
        setValue(value);
        toggleShow(false);

        if (typeof onSelect === "function") onSelect({
            value,
            clearSelect
        });
    }

    const handleAppend = () => {
        if (typeof append === "function") return append({
            clearSelect
        })

        return null;
    }

    return (
        <>
            <div
                style={style}
                className={`select-search ${className} ${disabled ? 'disabled' : ''}`}
                ref={selectRef}>
                <div
                    onClick={() => {!disabled && toggleShow(true); handleSearch()}}
                    className="form-select">
                    { value ? multipleLabel(value, config, 'label') : placeholder }
                </div>

                <div
                    className={`select-list shadow-sm list-group ${show ? 'show' : 'hide'}`}>
                    <div className="list-group-item list-group-search fs-3">
                        <input
                            ref={searchInputRef}
                            autoFocus
                            className="list-search"
                            style={{width: '100%'}}
                            onChange={handleSearch}
                            placeholder={`Recherche un élément`}
                            type="text"/>
                    </div>
                    { loading ? (
                        <div className="list-group-item text-center text-muted fs-4 p-4">Chargement en cours <FaSpinner className={`spin-animation`}/></div>
                    ) : (
                        <>
                            {/* Affichage si aucun résultat n'est disponible */}
                            { rows && rows.length === 0 ? (<div className="list-group-item p-4 py-3">Aucun résultat n'est disponible</div>) : null }

                            {/* Affichage des agences disponibles selon le critère de recherche */}
                            { rows && rows.map((row, index) => (<SelectItem
                                                                    className="p-4 py-3 fs-3"
                                                                    onClick={(value) => handleChange(value)}
                                                                    multipleLabel={multipleLabel}
                                                                    config={config}
                                                                    key={index}
                                                                    row={row}/>)) }
                        </>
                    )}
                    
                </div>
            </div>
            { handleAppend() }
        </>
    )
}

const mapStateToProps = (state) => {
    const { user } = state.AuthReducer;
    const { roleSelected, privilegeSelected } = user;
    return {
        roleSelected,
        privilegeSelected
    }
}

export default connect(mapStateToProps)(SelectSearch)
